import { useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import { useTranslation } from "next-i18next";
// COMPONENTS
import PriceTag from "@/components/core/ProductPage/PriceTag/PriceTag";
// CONTEXT
import { useAuth } from "@/components/auth/hooks/useAuth";
//BOOTSTRAP
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// ICONS
import { CheckCircleIcon2, DeleteIconCircle } from "@/public/icons/icons";
// STYLE
import s from "./ProductCard.module.scss";
// UTILS
import { getThumbFallBack_Url, getThumbEDGE_Url, getDefault_Url } from "common/images/utils";
import { isMobile } from "@/common/utils/sharedFunctions";
import { getSeasonsLabel } from "@/common/utils/productFunctions";
import fallbackTranslation from "@/lib/dbTranslations";
import { useApp } from "@/components/context/AppContext/AppContext";
import { shimmer, toBase64 } from "../PlaceholderImages/PlaceholderImages";
import CountdownTimer from "@/components/elements/CountdownTimer/CountdownTimer";

interface ProductCardProps {
  basePath: string;
  index: number;
  category: any;
  enableEditing: boolean;
  handleRemoveProduct?: any;
  handleAddProduct?: any;
  product: any;
}

export default function ProductCard({ basePath, index, category, enableEditing, handleRemoveProduct, handleAddProduct, product }: ProductCardProps) {
  const app = useApp();
  const auth = useAuth();
  const router = useRouter();
  const { t } = useTranslation("common");

  let colorObj = {};

  let colorList = [];
  // Render color list
  if (product.Colori != undefined && product.Colori.length > 0) {
    // gets the color list and creates the links
    colorList = product.Colori.slice(0, 9).map((color, index) => {
      colorObj = app.appConfig?.colors?.length > 0 && app.appConfig?.colors.find((el) => el.key == color);
      return (
        <OverlayTrigger
          key={index}
          placement="bottom"
          delay={{ show: 150, hide: 0 }}
          overlay={<Tooltip id={color}>{fallbackTranslation("colors", "db-translations", colorObj, t, router.locale)}</Tooltip>}
        >
          <div className="ms-0 me-1 text-decoration-none">
            <span className={s.circlecolor} style={{ backgroundColor: colorObj?.["value"] }}></span>
          </div>
        </OverlayTrigger>
      );
    });
  }

  // Add a placeholder if the image is not in the digital storage
  const getError = (e) => {
    e.target.srcset = getThumbFallBack_Url(e.target.currentSrc, getDefault_Url(product.Immagini));
  };

  return (
    <div>
      <Link href={`/${basePath}/${category}/${product._id}`} passHref>
        <a>
          <Card className={s.cardImage}>
            <div style={{ width: "100%" }}>
              <Image
                unoptimized
                layout="responsive"
                className={s.image}
                width={550}
                height={825}
                blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(550, 825))}`}
                placeholder="blur"
                priority={index < 6}
                alt={product.DescrizioneArticolo || "Product Image"}
                src={getThumbEDGE_Url(getDefault_Url(product?.Immagini))}
                sizes="(max-width: 576px) 50vw,
                (max-width: 992px) 33vw,
                (max-width: 1400px) 25vw,
                16vw"
                onError={getError}
              />
            </div>
            <Card.ImgOverlay className={`p-0 ${s.img_overlay}`}>
              <div className="p-2">
                <Badge bg={"dark"}>{`${product.presaleKitCode}`}</Badge>
              </div>
              <div
                style={{
                  height: "85%",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  justifyContent: enableEditing ? "space-between" : "flex-end",
                }}
              >
                {enableEditing && (
                  <div className="d-flex w-100">
                    <div className="w-100 d-flex justify-content-start">
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddProduct(product._id, product.Stato);
                        }}
                      >
                        <CheckCircleIcon2 backgroundColor="green" width={isMobile() ? 40 : 24} height={isMobile() ? 40 : 24} />
                      </Button>
                    </div>
                    <div className="w-100  d-flex justify-content-end">
                      <Button
                        variant="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleRemoveProduct(product._id, product.Stato);
                        }}
                      >
                        <DeleteIconCircle className="" backgroundColor="#DC3545" width={isMobile() ? 40 : 24} height={isMobile() ? 40 : 24} />
                      </Button>
                    </div>
                  </div>
                )}
                {product?.presaleEndDate && (
                  <div className="w-100 p-2 d-flex justify-content-center text-center">
                    <span className="text-white">
                      <CountdownTimer targetDate={product.presaleEndDate} />
                    </span>
                  </div>
                )}
              </div>
            </Card.ImgOverlay>
          </Card>
        </a>
      </Link>
      <div className="pt-2">
        <Link href={`/reservations/${category}/${product._id}`} passHref>
          <a>
            <div className={`fw-medium mb-0 text-truncate`}>
              <span>{product.DescrizioneArticolo}</span>
            </div>
          </a>
        </Link>
        <div>
          <span className="fs-7 mb-1">{product.CodiceArticolo}</span>
          {auth.isUserAdmin() && <span className="fs-7 mb-1 ps-1">/ {product.CodiceFornitore}</span>}
        </div>
        <div className="mb-2">
          <span>
            <PriceTag product={product} />
          </span>
        </div>
        <div className="d-flex" style={{ height: "24px" }}>
          {colorList}
          {product.Colori.length > 9 && <span className="ps-1 fs-7 fw-bold">+{product.Colori.length - 9}</span>}
        </div>
      </div>
    </div>
  );
}
