import { useCountdown } from "@/components/hooks/useCountdown";
import { useTranslation } from "next-i18next";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className="d-flex flex-column">
      <span className={`fs-6 px-1 trucante-text ${isDanger ? `text-warning` : `text-white`}`}>{value}</span>
      <span className={`fs-7 px-1 trucante-text ${isDanger ? `text-warning` : `text-white`}`}>{type}</span>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  // TODO: Add translation.

  const { t } = useTranslation("common");
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return (
      <div>
        <span>{t("common.presale-closed")}</span>
      </div>
    );
  } else {
    return (
      <div className="d-flex">
        <div className="mx-1 rounded-3 bg-opacity-75 bg-black">
          <DateTimeDisplay value={days} type={t("common.days")} isDanger={days <= 1} />
        </div>
        <div className="mx-1 rounded-3 bg-opacity-75 bg-black">
          <DateTimeDisplay value={hours} type={t("common.hours")} isDanger={days <= 1} />
        </div>
        <div className="mx-1 rounded-3 bg-opacity-75 bg-black">
          <DateTimeDisplay value={minutes} type={t("common.minutes")} isDanger={days <= 1} />
        </div>
      </div>
    );
  }
};

export default CountdownTimer;
