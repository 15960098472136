import { getInnerWindowSize } from "@/common/utils/sharedFunctions";
import { useEffect, useRef } from "react";
import ContentLoader from "react-content-loader";

const SkeletonPlaceholder = (props) => (
  <ContentLoader speed={1} viewBox="0 0 470 280" backgroundColor="#e8e8e8" foregroundColor="#d1d1d1" {...props}>
    <rect x="2" y="0" rx="5" ry="5" width="470" height="220" />
  </ContentLoader>
);

const SkeletonCarouselSmall = (props) => (
  <ContentLoader speed={1} viewBox="0 0 470 412" backgroundColor="#e8e8e8" foregroundColor="#d1d1d1" {...props}>
    <rect x="2" y="0" rx="5" ry="5" width="230" height="352" />
    <rect x="235" y="0" rx="5" ry="5" width="230" height="352" />
    {/* <rect x="323" y="0" rx="5" ry="5" width="146" height="220" /> */}
    {/* <circle cx="146" cy="400" r="3" /> */}
    {/* <circle cx="176" cy="400" r="3" /> */}
    <circle cx="206" cy="400" r="3" />
    <circle cx="236" cy="400" r="3" />
    <circle cx="266" cy="400" r="3" />
    {/* <circle cx="296" cy="400" r="3" /> */}
    {/* <circle cx="326" cy="400" r="3" /> */}
    <rect x="80" y="360" rx="5" ry="5" width="70" height="10" />
    <rect x="320" y="360" rx="5" ry="5" width="70" height="10" />
    {/* <rect x="362" y="225" rx="5" ry="5" width="70" height="10" /> */}
  </ContentLoader>
);

const SkeletonCarouselMedium = (props) => (
  <ContentLoader speed={1} viewBox="0 0 630 280" backgroundColor="#e8e8e8" foregroundColor="#d1d1d1" {...props}>
    <rect x="2" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="163" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="323" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="483" y="0" rx="5" ry="5" width="146" height="220" />
    {/* <circle cx="256" cy="255" r="3" /> */}
    <circle cx="286" cy="255" r="3" />
    <circle cx="316" cy="255" r="3" />
    <circle cx="346" cy="255" r="3" />
    {/* <circle cx="376" cy="255" r="3" /> */}
    <rect x="40" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="200" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="362" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="520" y="225" rx="5" ry="5" width="70" height="10" />
  </ContentLoader>
);

const SkeletonCarouselLarge = (props) => (
  <ContentLoader speed={1} viewBox="0 0 960 260" backgroundColor="#e8e8e8" foregroundColor="#d1d1d1" {...props}>
    <rect x="2" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="163" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="323" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="483" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="643" y="0" rx="5" ry="5" width="146" height="220" />
    <rect x="803" y="0" rx="5" ry="5" width="146" height="220" />
    {/* <circle cx="432" cy="255" r="3" />
    <circle cx="462" cy="255" r="3" />
    <circle cx="492" cy="255" r="3" />
    <circle cx="522" cy="255" r="3" /> */}
    <rect x="40" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="200" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="362" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="520" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="678" y="225" rx="5" ry="5" width="70" height="10" />
    <rect x="842" y="225" rx="5" ry="5" width="70" height="10" />
  </ContentLoader>
);

const SkeletonCarousel = () => {
  let windowSize = useRef({ height: undefined, width: undefined });
  useEffect(() => {
    windowSize.current = getInnerWindowSize();
  }, []);

  if (windowSize.current.width == undefined) {
    return (
      <div className="px-2">
        <SkeletonPlaceholder />
      </div>
    );
  }

  switch (windowSize.current.width !== undefined) {
    case windowSize.current.width >= 0 && windowSize.current.width < 768:
      return (
        <div className="px-2">
          <SkeletonCarouselSmall />
        </div>
      );
      break;
    case windowSize.current.width >= 768 && windowSize.current.width < 1200:
      return (
        <div className="px-2">
          <SkeletonCarouselMedium />
        </div>
      );
      break;
    case windowSize.current.width >= 1200:
      return (
        <div className="px-2">
          <SkeletonCarouselLarge />
        </div>
      );
      break;
    default:
      break;
  }
};

export { SkeletonPlaceholder, SkeletonCarousel };
