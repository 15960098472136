import ProductCardCatalog from "../../Catalogo/ProductCard/ProductCard";
import ProductCardPresale from "../../Presale/ProductCard";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
// COMPONENTS
import Slider from "react-slick";
import { SkeletonCarousel } from "../../Skeletons/SkeletonCarousel";
import { useApp } from "@/components/context/AppContext/AppContext";
// STYLE
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import s from "./Carousel.module.scss";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return <div id="next-arrow" className={className} style={{ ...style, display: "block" }} onClick={onClick} />;
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return <div id="prev-arrow" className={className} style={{ ...style, display: "block" }} onClick={onClick} />;
}

interface CarouselProps {
  autoplay?: boolean;
  presaleMode?: boolean;
  products: any;
  slides: number;
  slidesMd?: number;
  slidesSm?: number;
  slidesXl?: number;
  title: string;
  titleStyle?: string;
}

export default function Carousel({
  autoplay = true,
  presaleMode = false,
  products,
  slides,
  slidesMd = 3,
  slidesSm = 2,
  slidesXl = 4,
  title,
  titleStyle,
}: CarouselProps) {
  const app = useApp();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [clothingProductsGroup, setclothingProductsGroup] = useState([]);

  // Set colors and sizes from app context, check if values are ready.
  useEffect(() => {
    if (app.appConfig?.clothingProductsGroup) {
      setclothingProductsGroup(app.appConfig?.clothingProductsGroup);
    }
  }, [app]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 80);
  }, []);

  const settings = {
    accessibility: true,
    adaptiveHeight: true,
    arrows: true,
    autoplay: autoplay,
    dots: true,
    infinite: true,
    initialSlide: 0,
    lazyLoad: false,
    pauseOnFocus: true,
    pauseOnHover: true,
    slidesToScroll: slides,
    slidesToShow: slides,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: slidesXl,
          slidesToScroll: slidesXl,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: slidesMd,
          slidesToScroll: slidesMd,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: slidesSm,
          slidesToScroll: slidesSm,
        },
      },
    ],
  };

  const slideData = products?.map((product, i) => {
    return (
      <div className={`${s.slidata_wrapper}`} key={`slideData-${product?._id}`}>
        {presaleMode ? (
          <ProductCardPresale
            basePath={"presales"}
            index={i}
            enableEditing={false}
            product={product}
            key={product?._id}
            category={
              router.query?.["GruppoMerceologico"]
                ? router.query?.["GruppoMerceologico"]
                : product?.GruppoMerceologico?.[0].toLowerCase() || "category"
            }
          />
        ) : (
          <ProductCardCatalog
            showPrice={false}
            index={i}
            enableEditing={false}
            product={product}
            key={product?._id}
            category={
              router.query?.["GruppoMerceologico"]
                ? router.query?.["GruppoMerceologico"]
                : product?.GruppoMerceologico?.[0].toLowerCase() || "category"
            }
          />
        )}
      </div>
    );
  });

  return (
    <div className="pb-4">
      <h2 className={`${titleStyle} px-2 pb-2`}>{title}</h2>
      {isLoading ? (
        <SkeletonCarousel />
      ) : (
        <Slider {...settings} className="px-0">
          {slideData}
        </Slider>
      )}
    </div>
  );
}
